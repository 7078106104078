<template>
    <div>
        <licenses></licenses>
    </div>

</template> 


<script>

import licenses from '/src/components/components/licenses'

export default {
        name:"licensesettings",
        data: function () {
             return {


             }
         },
         mounted() {
            
         },
         components: {
             licenses
         },

         methods: {

            }
}
</script>